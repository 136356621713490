
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































































































































































































































































.payment-intro {
  font-size: 1.4rem;
}

.payment-box {
  max-width: 600px;
  width: 100%;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
  font-size: 1.4rem;
  margin: 2em 0;
}

.payment-box--header {
  padding: 2em;
  background-color: white;
  border-bottom: 1px solid #d1d1d1;
  color: #e84e0f;
}

.payment-box--header .h4 {
  margin: 0 0 0.6em 0;
  font-weight: 500;
  font-size: 1.7rem;
  padding-left: 1.8em;
  background: left center url('../../../assets/images/danger.svg') no-repeat;
  background-size: 1.1em;
  background-position: left top -2px;
  line-height: 1.6rem;
}

.payment-box--header p {
  margin: 0;
  font-weight: 400;
}
.record-title {
  background-image: url('../../../assets/images/ico-thumb.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 60px 60px;
  color: $c-main-primary;
  font-family: $ff-alt;
  margin-top: 15px;
  padding: 15px 0 15px 65px;
}

.payment-box--main {
  padding: 1.5em 50% 1.5em 1.5em;
  position: relative;
}

@media screen and (max-width: 600px) {
  .payment-box--main {
    padding: 1.5em;
  }
}

.payment-box--main p,
.payment-box--footer p {
  margin: 0;
}

.payment-box--submit {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  background: #ff4b00;
  text-decoration: none;
  color: white;
  padding: 0.8em 2em;
  border-radius: 100px;
  transition: background 0.25s;
  display: inline-block;
}

.payment-box--submit:is(:hover, :active) {
  background: #ff7238;
}

@media screen and (max-width: 600px) {
  .payment-box--submit {
    position: static;
    margin: 1em 0;
  }
}

.payment-box--footer {
  padding: 1em 20% 1em 0;
  margin: 0 1.5em;
  border-top: 1px dashed #d1d1d1;
  font-style: italic;
  color: #5e5e5e;
  position: relative;
}

.payment-box--footer figure {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.payment-box--footer figcaption {
  display: none;
}

.payment-box--footer img {
  height: 3.5em;
}

.payment-box--footer p {
  padding-left: 1.8em;
  background: left center url('../../../assets/images/locked-padlock.svg')
    no-repeat;
  background-size: 1.1em;
}
.montantColor {
  color: #ff4b00;
}
